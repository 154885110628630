import {combineReducers} from 'redux';

import {user} from './user';
import {locations} from './locations';
import {subjects} from './subjects';
import {alerts} from './alerts'

export default combineReducers({
  user,
  locations,
  subjects,
  alerts
});
