import * as t from '../types';

// const initialState = {
//   dcEmail: null,
//   dcKey: null,
//   token: null,
// };
const initialState = {
  wyseEmail: null,
  wyseKey: null,
  token: null,
};

export const user = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case t.USER_SET_AUTH_TOKEN: {
      // const {dcEmail, dcKey, token} = payload;
      // return {
      //   ...state,
      //   dcEmail,
      //   dcKey,
      //   token,
      // };
      const {wyseEmail, wyseKey, token} = payload;
      return {
        ...state,
        wyseEmail,
        wyseKey,
        token,
      };
    }

    case t.USER_CLEAR_AUTH_TOKEN: {
      // return {
      //   ...state,
      //   dcEmail: null,
      //   dcKey: null,
      //   token: null,
      // };
      return {
        ...state,
        wyseEmail: null,
        wyseKey: null,
        token: null,
      };
    }

    default:
      return state;
  }
};
