import * as t from '../types';

const initialState = {
  alertList: [],
};

export const alerts = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case t.ALERT_SET_ALERTS: {
      const {alerts: alertList} = payload;
      return {
        ...state,
        alertList,
      };
    }

    default:
      return state;
  }
};
