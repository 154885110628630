import {postRequest} from './restApi';

// const AUTH_REST_API_ROOT_URL = 'https://us-central1-wysetime.cloudfunctions.net';
const AUTH_REST_API_ROOT_URL = 'https://us-central1-wysetime-aishop.cloudfunctions.net';

export const signUpRequest = async (userData) => postRequest(`${AUTH_REST_API_ROOT_URL}/authSignUp`, userData, true);

export const signInRequest = async (email, password) => postRequest(`${AUTH_REST_API_ROOT_URL}/authSignIn`, {email, password}, true);

export const changePasswordRequest = async (password, token) => postRequest(`${AUTH_REST_API_ROOT_URL}/userChangePassword`, {password}, true, token);

export const storeLoginInfo = (wyseEmail, wyseKey, token) => {
  // sessionStorage.setItem('dcEmail', dcEmail);
  // sessionStorage.setItem('dcKey', dcKey);
  sessionStorage.setItem('wyseEmail', wyseEmail);
  sessionStorage.setItem('wyseKey', wyseKey);
  sessionStorage.setItem('token', token);
};

export const clearLoginInfo = () => sessionStorage.clear();

export const retrieveLoginInfo = () => {
  // const dcEmail = sessionStorage.getItem('dcEmail');
  // const dcKey = sessionStorage.getItem('dcKey');
  const wyseEmail = sessionStorage.getItem('wyseEmail');
  const wyseKey = sessionStorage.getItem('wyseKey');
  const token = sessionStorage.getItem('token');
  return (wyseEmail && wyseKey && token) ? {wyseEmail, wyseKey, token} : null;
};
